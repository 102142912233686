import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';

const SocialMediaLinks = () => {
  const { fb, ig, yt } = useStaticQuery(graphql`
    query {
      fb: file(relativePath: { eq: "fb.png" }) {
        childImageSharp {
          fluid(maxWidth: 50, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ig: file(relativePath: { eq: "ig.png" }) {
        childImageSharp {
          fluid(maxWidth: 50, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      yt: file(relativePath: { eq: "yt.png" }) {
        childImageSharp {
          fluid(maxWidth: 50, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <>
      <div className="d-flex">
        <a
          href="https://www.facebook.com/ganenphilippines/"
          target="_blank"
          rel="noreferrer"
          className="mr-4"
        >
          <BackgroundImage
            fluid={fb.childImageSharp.fluid}
            style={{ width: '35px', height: '35px' }}
          ></BackgroundImage>
        </a>

        <a
          href="https://www.instagram.com/ganenphilippines/"
          target="_blank"
          rel="noreferrer"
          className="mr-4"
        >
          <BackgroundImage
            fluid={ig.childImageSharp.fluid}
            style={{ width: '35px', height: '35px' }}
          ></BackgroundImage>
        </a>

        <a
          href="https://www.youtube.com/channel/UCTkW_g7cvDsb-_WXQ683WJA"
          target="_blank"
          rel="noreferrer"
        >
          <BackgroundImage
            fluid={yt.childImageSharp.fluid}
            style={{ width: '35px', height: '35px' }}
          ></BackgroundImage>
        </a>
      </div>
    </>
  );
};

export default SocialMediaLinks;
