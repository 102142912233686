import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';

import { Link } from 'gatsby';

import Logo from './header/logo';
import CallButton from './header/call-button';
import Nav from './header/nav';
import MobileNav from './header/mobile-nav';

const Header = () => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  const toggleMobileMenu = (e) => {
    e.preventDefault();
    setIsOpenMobileMenu(!isOpenMobileMenu);
  };

  return (
    <header>
      <div className="box-container row mx-auto m-md-auto">
        <div className="d-inline-flex d-md-none col-3 align-items-center">
          <Link to="/#" onClick={toggleMobileMenu}>
            <FaBars size={35} className="mx-1 ganen-brown" />
          </Link>
        </div>

        <div className="col-6 p-0 p-md-2">
          <Logo />
        </div>

        <div className="col-3 col-md-6">
          <CallButton />
        </div>

        <div className="col-12">
          <Nav />
        </div>
      </div>

      <div className={`mobile-nav ${isOpenMobileMenu ? 'open' : ''}`}>
        <MobileNav toggleMobileNav={setIsOpenMobileMenu} />
      </div>
    </header>
  );
};

export default Header;
