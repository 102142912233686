import React from 'react';
import Helmet from 'react-helmet';

import { graphql, useStaticQuery } from 'gatsby';

import favicon32 from '../../images/favicon-32x32.png';

const Head = ({ title }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          keywords
        }
      }
    }
  `);

  return (
    <Helmet>
      <title>{`${title} | ${site.siteMetadata.title}`}</title>

      <meta name="description" content={`${site.siteMetadata.description}`} />
      <meta name="keywords" content={`${site.siteMetadata.keywords}`} />

      <link rel="icon" type="image/png" sizes="32x32" href={`${favicon32}`} />
    </Helmet>
  );
};

export default Head;
