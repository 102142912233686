import React from 'react';

import BackgroundImage from 'gatsby-background-image';
import { graphql, Link, useStaticQuery } from 'gatsby';

const Logo = () => {
  const { logo, mobileLogo } = useStaticQuery(graphql`
    query {
      mobileLogo: file(relativePath: { eq: "ganen-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "ganen-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="logo">
      <Link to="/">
        <div className="logo-image-div"></div>
      </Link>

      <style jsx="true">{`
        @media screen and (max-width: 600px) {
          .logo-image-div {
            background-image: url(${mobileLogo.childImageSharp.fluid.srcWebp});
          }
        }

        @media screen and (min-width: 601px) {
          .logo-image-div {
            background-image: url(${logo.childImageSharp.fluid.srcWebp});
          }
        }

        .logo-image-div {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          opacity: 0.99;
        }
      `}</style>
    </div>
  );
};

export default Logo;
