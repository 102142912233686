import React, { useState, useEffect } from 'react';

import { Link } from 'gatsby';

import HomeSVG from '../../../images/home.svg';
import ShieldSVG from '../../../images/shield.svg';
import BulbSVG from '../../../images/bulb.svg';

const Nav = () => {
  const [activePath, setActivePath] = useState('');

  useEffect(() => {
    if (window !== null) {
      const currentPathname = window.location.pathname;
      if (currentPathname.indexOf('/home-automation') > -1) {
        setActivePath('ha');
      } else if (currentPathname.indexOf('/home-security') > -1) {
        setActivePath('hs');
      } else if (currentPathname.indexOf('/home-lighting') > -1) {
        setActivePath('hl');
      } else if (currentPathname.indexOf('/our-products') > -1) {
        setActivePath('op');
      } else if (currentPathname.indexOf('/contact-us') > -1) {
        setActivePath('cu');
      }
    }
  }, []);

  return (
    <nav className="header-nav d-none d-md-block">
      <ul>
        <li>
          <Link
            to="/home-automation"
            className={activePath === 'ha' ? 'active' : ''}
          >
            <HomeSVG className="hw-18 pr-t-4 text-primary d-none d-lg-inline-block" />{' '}
            Home Automation
          </Link>
        </li>
        <li>
          <Link
            to="/home-security"
            className={activePath === 'hs' ? 'active' : ''}
          >
            <ShieldSVG className="hw-18 pr-t-4 text-success d-none d-lg-inline-block" />{' '}
            Home Security
          </Link>
        </li>
        <li>
          <Link
            to="/home-lighting"
            className={activePath === 'hl' ? 'active' : ''}
          >
            <BulbSVG className="hw-18 pr-t-4 text-danger d-none d-lg-inline-block" />{' '}
            Home Lighting
          </Link>
        </li>
        <li>
          <Link
            to="/our-products"
            className={activePath === 'op' ? 'active' : ''}
          >
            Our Products
          </Link>
        </li>
        <li>
          <Link
            to="/contact-us"
            className={activePath === 'cu' ? 'active' : ''}
          >
            Contact Us
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
