import React from 'react';

import PropTypes from 'prop-types';

import Header from './header';
import Content from './content';
import Footer from './footer';

import '../../styles/main.scss';

const Main = ({ children }) => (
  <div className="main-wrapper">
    <Header />
    <Content>{children}</Content>
    <Footer />
  </div>
);

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
