import React, { useState, useEffect } from 'react';

import { Link } from 'gatsby';

import { FaTimes } from 'react-icons/fa';

import HomeSVG from '../../../images/home.svg';
import ShieldSVG from '../../../images/shield.svg';
import BulbSVG from '../../../images/bulb.svg';

const MobileNav = ({ toggleMobileNav }) => {
  const [activePath, setActivePath] = useState('');

  useEffect(() => {
    if (window !== null) {
      const currentPathname = window.location.pathname;
      if (currentPathname.indexOf('/home-automation') > -1) {
        setActivePath('ha');
      } else if (currentPathname.indexOf('/home-security') > -1) {
        setActivePath('hs');
      } else if (currentPathname.indexOf('/home-lighting') > -1) {
        setActivePath('hl');
      } else if (currentPathname.indexOf('/our-products') > -1) {
        setActivePath('op');
      } else if (currentPathname.indexOf('/contact-us') > -1) {
        setActivePath('cu');
      }
    }
  }, []);

  const toggleNav = (e) => {
    e.preventDefault();
    toggleMobileNav();
  };

  return (
    <div>
      <div className="h-70 d-flex align-items-center pl-3">
        <Link to="/#" className="mx-1" onClick={toggleNav}>
          <FaTimes size={35} className="ganen-brown" />
        </Link>
      </div>

      <div>
        <nav className="header-mobile-nav">
          <ul>
            <li>
              <Link
                to="/home-automation"
                className={activePath === 'ha' ? 'active' : ''}
              >
                <HomeSVG className="hw-25 pr-t-4 text-primary" />
                <span className="ganen-bold pl-3 fs-20">Home Automation</span>
              </Link>
            </li>
            <li>
              <Link
                to="/home-security"
                className={activePath === 'hs' ? 'active' : ''}
              >
                <ShieldSVG className="hw-25 pr-t-4 text-success" />
                <span className="ganen-bold pl-3 fs-20">Home Security</span>
              </Link>
            </li>
            <li>
              <Link
                to="/home-lighting"
                className={activePath === 'hl' ? 'active' : ''}
              >
                <BulbSVG className="hw-25 pr-t-4 text-danger" />
                <span className="ganen-bold pl-3 fs-20">Home Lighting</span>
              </Link>
            </li>
            <li>
              <Link
                to="/our-products"
                className={activePath === 'op' ? 'active' : ''}
              >
                <div className="hw-25 pr-t-4 d-inline-block" />
                <span className="ganen-bold pl-3 fs-20">Our Products</span>
              </Link>
            </li>
            <li>
              <Link
                to="/contact-us"
                className={activePath === 'cu' ? 'active' : ''}
              >
                <div className="hw-25 pr-t-4 d-inline-block" />
                <span className="ganen-bold pl-3 fs-20">Contact Us</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileNav;
