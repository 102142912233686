import React from 'react';

import FooterLinks from './footer/footer-links';
import SocialMediaLinks from './footer/social-media-links';

const Footer = () => (
  <footer className="py-5">
    <div className="box-container row m-0 mx-md-auto">
      <FooterLinks />

      <div className="lower-footer col-12 row m-0 mx-md-auto p-0">
        <div className="d-none d-lg-block col-1" />

        <div className="copyright col-12 col-md-8 pl-4 fs-14 d-flex align-items-end mt-5">
          Copyright &copy; 2020 Ganen Asia Pte. Ltd All rights reserved.
        </div>

        <div className="soc-med col-12 col-md-3 pl-4 mt-5">
          <SocialMediaLinks />
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
