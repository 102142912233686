import React from 'react';

import { Link } from 'gatsby';

const FooterLinks = () => (
  <>
    <div className="d-none d-lg-block col-1" />

    <div className="col-12 col-md-4 col-lg-3 pl-4 pr-0 pt-5">
      <h5 className="fs-20">Products and Solutions</h5>

      <div className="mb-2">
        <Link to="/home-automation" className="fl">
          Home Automation
        </Link>
      </div>
      <div className="mb-2">
        <Link to="/home-security" className="fl">
          Home Security
        </Link>
      </div>
      <div className="mb-2">
        <Link to="/home-lighting" className="fl">
          Home Lighting
        </Link>
      </div>
      <div className="mb-2">
        <Link to="/our-products" className="fl">
          Our Products
        </Link>
      </div>
      <div className="mb-2">
        <Link to="/home-job-references" className="fl">
          Home Job References
        </Link>
      </div>
      <div className="mb-2">
        <Link to="/office-job-references" className="fl">
          Office Job References
        </Link>
      </div>
      <div className="mb-2">
        <Link to="/hoteliers-and-property-developers" className="fl">
          Hoteliers and Property Developers
        </Link>
      </div>
    </div>

    <div className="col-12 col-md-3 col-lg-3 pl-4 pr-0  pt-5">
      <h5 className="fs-20">Company</h5>

      <div className="mb-2">
        <Link to="/about-ganen" className="fl">
          About GANEN
        </Link>
      </div>
      <div className="mb-2">
        <Link to="/contact-us" className="fl">
          Contact Us
        </Link>
      </div>
    </div>

    <div className="col-12 col-md-4 col-lg-3 pl-4 pr-0  pt-5">
      <h5 className="fs-20">Legal</h5>

      <div className="mb-2">
        <Link to="/terms-and-conditions" className="fl">
          Terms and Conditions
        </Link>
      </div>
      <div className="mb-2">
        <Link to="/privacy-policy" className="fl">
          Privacy Policy
        </Link>
      </div>
    </div>

    <div className="d-none d-lg-block col-1" />
  </>
);

export default FooterLinks;
