import React from 'react';

import PropTypes from 'prop-types';

const Content = ({ children }) => (
  <main>
    <div className="main-container">{children}</div>
  </main>
);

Content.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Content;
