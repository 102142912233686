import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import CallSVG from '../../../images/call.svg';

const CallButton = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          contactNumber
        }
      }
    }
  `);

  return (
    <div className="text-right">
      <div className="d-inline-block pt-3 pt-md-1">
        <div className="text-left px-4 fs-13 ganen-gray d-none d-md-block">
          Get a free quote
        </div>
        <a
          className="call-button-link btn bg-ganen-brown rounded-25 text-white px-3 px-sm-4"
          href={`tel:${site.siteMetadata.contactNumber}`}
        >
          <CallSVG className="hw-18 pr-t-3" />{' '}
          <span className="d-none d-md-inline-block ganen-bold">{`${site.siteMetadata.contactNumber}`}</span>
        </a>
      </div>
    </div>
  );
};

export default CallButton;
